.page {
  &.marg-bottom {
    margin-bottom: 10px;
  }
  .page-title {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
  }
  .page-text {
    font-size: 14px;
  }
  .page-action {
    font-weight: bold;
    font-size: 22px;
    text-align: center;

    margin: 10px 0;
    margin-bottom: 20px;

    position: relative;

    .page-action-back {
      position: absolute;
      left: 0;
      display: inline-block;

      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: .2;
      }

      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;

      box-shadow: 0px 7px 24px #00000012;
      border-radius: 6px;
    }
  }

  .consultant-item {
    margin-top: 10px;

    padding: 10px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #ECEBED;
    border-radius: 12px;
    opacity: 1;

    .consultant-image {
      display: inline-block;
      .consultant-image-img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, .5);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
      }
    }

    .consultant-name {
      color: #1899F5;
      font-size: 17px;
      font-weight: bold;
    }
    .consultant-job {
      margin-top: 2px;
      font-size: 12px;
      font-weight: bold;
    }
    .consultant-location {
      margin-top: 5px;
      font-size: 12px;
      font-weight: bold;
    }
    .consultant-postal {
      font-size: 12px;
      font-weight: bold;
    }
    .consultant-distance {
      margin-top: 5px;
      font-size: 12px;
    }

    .consultant-date {
      margin-top: 5px;
      font-size: 14px;
    }
    //.consultant-time {
    //  //margin-top: 5px;
    //  font-size: 12px;
    //}

    .consultant-select {
      //padding: 10px 20px 0;
      margin-top: 10px;
    }
  }
  .cancel-button {
    padding: 10px 0 0 0;
    &.second {
      padding-top: 15px;
    }
    .btn {
      display: inline-block;
      width: calc(50% - 10px);
      padding: 0 5px;
    }
  }
}