.appointments {
  //padding: 20px;
  text-align: center;

  //width: 100vw;

  //position: absolute;
  //top: 0;
  //left: 0;

  //height: 100vh;
  //height: -webkit-fill-available;

  //margin-bottom: 80px;
  //overflow: auto;

  .text-in {
    padding: 20px;
  }
  .appointments-list {
    padding: 20px;
    text-align: left;
  }
}
