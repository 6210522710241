.textbox {
  //position: relative;
  //width: 100%;

  /*.icon {
    width: 24px;
    height: 24px;
    background: transparent url('../../gfx/search.svg') 0% 0% no-repeat padding-box;
    position: absolute;

    top: 16px;
    left: 15px;
  }*/

  border: 1px solid #CACACA;
  border-radius: 8px;

  .clear-text {
    cursor: pointer;

    font-size: 16px;
    color: #1899F5;
    display: inline-block;
    //padding-left: 10px;
    float: right;
    font-weight: 700;
    vertical-align: middle;
    //line-height: 42px;
    //margin-top: 10px;
    margin-top: 8px;

    margin-right: 10px;
  }
  .textbox-input {
    //position: ;
    width: calc(100% - 40px - 25px - 10px);

    display: inline-block;

    height: 40px;
    //background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    //background: #FFFFFF 0% 0% no-repeat padding-box;
    //box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #F3F3F3;
    //border-radius: 6px;
    opacity: 1;
    //background: #F3F3F3;
    background: #fff;
    //border-radius: 8px;

    //font: normal normal normal 15px/20px Lato;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    padding: 0 15px;

    //padding-left: 45px;

    /*&:focus {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000012;
      border: 1px solid #ECEBED;
      border-radius: 6px;
      opacity: 1;
    }*/
  }
}
