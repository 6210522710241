.week-calendar {
  background: #FFFFFF;
  //border: 1px solid #CACACA;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;

  //&.placeholder {
  //  opacity: .45;
  //  .go-back, .go-forward, .label {
  //    opacity: .5;
  //  }
  //}

  .top-label {
    margin-bottom: 10px;

    line-height: 16px;
    display: flex;
    flex-direction: row;

    .go-left {
      text-align: right;
      .go-btn {
        text-align: left;
        display: inline-block;
        right: 0;
      }
    }
    .go-right {
      svg {
        margin-left: -2px;
      }
    }
    .go-left, .go-right {
      user-select: none;

      display: inline-block;
      .go-btn {
        cursor: pointer;
      }

      height: 25px;

      flex: 100;

      svg {
        color: #DADADA;
      }

      &.enabled {
        line-height: 25px;
        svg {
          color: #fff;
        }

        .go-btn {
          width: 25px;
          height: 25px;
          background: #1899F5;
          border-radius: 8px;
        }
      }
    }

    .go-back, .go-forward {
      display: inline-block;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: .2;
      }

      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;

      font-size: 24px;
    }

    .label {
      padding: 0 20px;
      display: inline-block;
      text-align: center;
      //width: calc(100% - 20px);
      //width: calc(7*32px - 20px);
      //width: calc(7*32px - 48px);
      width: auto;
      flex: auto;
      font-size: 16px;
      font-weight: bold;

      line-height: 24px;

      user-select: none;
    }
  }

  .show-more-appts {
    text-align: center;
    //padding: 10px 0;
    margin: 10px auto;
    .small-text {
      border: 1px solid #CACACA;
      //border-radius: 30px;
      padding: 8px 15px;

      font-style: normal;
      //font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-align: center;

      //background: #1899F5;
      //border: 1px solid #1899F5;
      border-radius: 8px;
      //color: #fff;
      //text-align: center;
      font-weight: bold;
    }

    cursor: pointer;

    &.disabled {
      opacity: .5;
      cursor: default;
    }
  }

  table {
    width: 100%;
    .week-day {
      text-align: center;
      font-size: 12px;
      width: 14.29%;
      cursor: pointer;
      &.disabled {
        cursor: no-drop;
        //opacity: .25;
        color: #808A92;
      }
      padding: 0 0 7px;
    }
    .single-column-td {
      .column {
        cursor: pointer;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        padding: 5px;
        margin: 0 2px;
        background: #F3F3F3;
        border-radius: 8px;

        &.selected {
          background: #1899F5;
          color: #fff;
        }
        &.disabled {
          cursor: no-drop;
          opacity: .25;
        }
      }
    }

    .multi-column-td {
      //text-align: center;
      //margin: auto;

      .column {
        cursor: pointer;
        text-align: center;
        //font-size: 11px;
        font-size: 14px;
        font-weight: bold;
        padding: 8px;
        margin: 0 auto;
        //background: #F3F3F3;
        //border-radius: 8px;

        width: 20px;
        height: 20px;
        line-height: 20px;

        &.selected {
          background: #1899F5;
          color: #fff;
          border-radius: 50%;
          //width: 20px;
          //height: 20px;
        }
        &.disabled {
          cursor: no-drop;
          opacity: .25;
        }
      }
    }
  }
}