.app-main-page-fv {
  .app-text-bold {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #1899F5;
  }

  .new-app-text-top {
    font-size: 20px;
    padding: 22px 0;
    font-weight: bold;
    text-align: center;

    .go-back-stage {
      cursor: pointer;
      float: left;
      color: #CACACA;
    }
  }

  .app-select-item-label {
    padding-top: 10px;

    .select-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    .select-description {
      padding-top: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 12.5px;
      line-height: 15px;
      //text-align: center;
    }
  }

  .app-summary {

    padding: 10px 0;
    //margin-bottom: 20px;
    margin-bottom: 20vh;

    .app-summary-full-name {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;

      color: #1899F5;
    }

    .app-summary-speciality {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */

      text-align: center;

      color: #434C5A;
    }

    .app-summary-location {
      padding-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      color: #434C5A;
    }

    .app-summary-regional {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      color: #434C5A;
    }

    .app-summary-when {
      padding-top: 14px;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #000000;
    }

    .app-summary-confirm {
      margin-top: 25px;
    }
  }

  .app-disabled-element {
    pointer-events: none;

    opacity: .15;
    filter: grayscale(1);
  }

  .single-step-item {
    //margin-top: 10px;
  }

  .consultant-filers {
    margin: 15px 0 10px;
    display: flex;

    .consultant-filers-button {
      float: right;
    }

    .consultant-filers-label {
      position: absolute;
      display: inline;
      font-size: 12px;
      background: #fff;
      color: #000000;
      top: -9px;
      left: 5px;
      padding: 0 5px;
    }

    .consultant-filers-where {
      position: relative;
      padding: 8px 10px;
      flex: 3;
      border: 1px solid #CACACA;
      border-radius: 8px;
      margin-right: 10px;
      font-size: 16px;

      cursor: pointer;
    }

    .consultant-filers-far {
      position: relative;
      padding: 8px 10px;
      flex: 1.1;
      border: 1px solid #CACACA;
      border-radius: 8px;
      font-size: 16px;

      cursor: pointer;
    }
  }

  .app-select-item {
    padding: 10px 0 5px;

    &.sm-padding {
      padding-top: 0;
    }
  }

  .new-search-utils {
    .search-switch-item {
      cursor: pointer;
      padding: 15px 20px 5px 0;
      display: inline-block;

      .search-switch-icon {
        display: inline-block;
        vertical-align: middle;
        padding-right: 5px;
      }

      .search-switch-label {
        display: inline-block;
      }
    }
  }

  .app-button-placeholder {
    //cursor: default;
    user-select: none;
  }

  .app-button-enabled {
    cursor: pointer;
    user-select: none;
  }

  .app-button-simple {
    width: calc(100% - 20px);
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 30px;

    &.app-button-back {
      //color: #898A8D;
      //background: #F3F3F3;
      //border: 1px solid #F3F3F3;
      border: 1px solid #CACACA;

      &.blue {
        background: #1899F5;
        border: 1px solid #1899F5;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        font-weight: bold;
      }
    }

    &.app-button-clear {
      border: 1px solid #CACACA;
      font-weight: bold;
    }
  }

  .app-big-modal-selector-back {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100vw;
    max-width: 760px;
    height: 100vh;
    height: -webkit-fill-available;
    background-color: rgba(255, 255, 255, .05);
  }

  .confirmation-window {
    overflow-y: auto;

    .confirm-image {
      padding-top: 50px;
      text-align: center;

      img {
        width: 220px;
      }
    }

    .confirm-info-box {
      background: #FFFFFF;
      border: 1px solid #CACACA;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      margin-bottom: 40px;
      padding: 20px 0;
      margin-top: 10px;
    }

    .confirm-label {
      font-size: 20px;
      //line-height: 24px;
      text-align: center;
      color: #1899F5;
      font-weight: bold;
      //margin-top: 20px;
    }

    .confirm-message {
      margin: 20px 0 10px;
      text-align: center;

      font-size: 20px;
      .confirm-spec {
        font-weight: 700;
        font-size: 13px;
        color: #434C5A;
      }
    }

    .confirm-message-location1 {
      text-align: center;
      margin-top: 20px;
      //font-weight: bold;
      font-size: 14px;
      color: #434C5A;
    }

    .confirm-message-location2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 14px;
      color: #434C5A;
    }

    .confirm-auth-code {
      //border-radius: 8px;
      //border: 2px solid #1899F5;
      background: #FAFAFA;
      border-radius: 8px;

      padding: 10px 20px;
      //font-weight: bold;

      color: #424242;
      font-size: 14px;

      .code {
        //color: #1899F5;
        font-weight: bold;
        margin-left: 20px;
        font-size: 18px;
        color: #000000;
      }

      margin-top: 10px;
      margin-bottom: 40px;
    }

    .confirm-summary {
      font-size: 14px;
      //text-align: center;
      text-align: left;
      margin-top: 0;
      margin-bottom: 20px;

      padding: 0 20px;
    }

    .confirm-close-button {
      cursor: pointer;
      margin: 10px 20px;
      padding: 10px 20px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background: #1899F5;
      border-radius: 8px;

      &.disabled {
        background: #eaeaea;
      }
    }
  }

  .app-big-modal-selector {
    position: absolute;
    //top: 100px;
    z-index: 10;
    left: 0;
    width: 100vw;
    max-width: 760px;
    height: 100vh;
    height: -webkit-fill-available;
    //background-color: rgba(255, 255, 255, .95);
    background-color: #fff;

    // FIXME: Daryn footer ...
    //padding-bottom: 80px;

    //overflow: auto;

    transition: all .25s;
    //transition: all .5s;

    display: flex;
    flex-direction: column;

    .app-item-summary {
      overflow-y: auto;

      .top_image {
        img {
          width: 100%;
          //max-width: 500px;
          height: 237px;
          object-fit: contain;
        }
      }

      .center-info {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding-top: 20px;
      }

      .consultant-summary {
        background: #FFFFFF;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 1px solid #CACACA;

        margin: 20px;
        padding: 10px 20px;

        .consultant-summary-split {
          display: flex;
          flex-direction: row;
        }

        .consultant-panel-item1 {
          width: 110px;
          max-width: 110px;
          min-width: 110px;

          .empty-picture {
            width: 110px;
            height: 120px;

            .empty-picture-img {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              background-color: #eaeaea;
            }
          }

          .consultant-image {
            display: inline-block;

            .consultant-image-img {
              width: 90px;
              height: 90px;
              object-fit: cover;
              border-radius: 50%;
              //border: 2px solid rgba(255, 255, 255, .5);
              //box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
            }
          }

          .consultant-profile-button {
            font-size: 12px;
            //border: 1px solid #BBBBBB;
            //color: #888;
            //border-radius: 30px;
            text-align: center;
            padding: 4px 0;
            margin-top: 10px;

            cursor: pointer;

            background: #1899F5;
            border: 1px solid #1899F5;
            border-radius: 8px;
            color: #fff;
            font-weight: bold;

            &.disabled {
              opacity: .15;
            }
          }
        }

        .consultant-panel-full-name {
          color: #1899F5;
          font-size: 16px;
          font-weight: bold;
        }

        .consultant-panel-info-line1 {
          font-size: 13px;
          font-weight: bold;
        }

        .consultant-panel-info-line2 {
          font-size: 13px;

          .select-button {
            float: right;
            //margin-right: 5px;
            //margin-bottom: 10px;
            margin-top: -10px;
            background: #1899F5;
            border-radius: 30px;
            padding: 5px 20px;

            color: #FFFFFF;
            font-size: 13px;
          }
        }

        .with-big-font {
          font-size: 17px;

          &.bolder {
            font-weight: bold;
          }
        }

        .consultant-panel-item2 {
          //width: 40px;
          flex: auto;

          .with-spacing {
            margin-bottom: 10px;
          }
        }

        .consultant-panel-item3 {
          margin-top: 10px;
        }

        .consultant-panel-item4 {
          margin-top: 20px;
        }

        .consultant-panel-item5 {
          margin-top: 20px;
        }
      }
    }

    .buttons-confirmation {
      padding: 0 20px 40px;
      display: flex;
      flex-direction: row;
      font-weight: bold;

      .cancel-button {
        flex: 1;
        text-align: center;
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 8px;

        margin-right: 5px;
        cursor: pointer;
      }

      .confirm-button {
        flex: 1;
        text-align: center;

        background: #1899F5;
        border-radius: 8px;
        color: #fff;
        padding: 10px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .app-item-specialty {
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100vw - 40px);
      //width: 100vw;
      max-width: 760px;
      //margin: 0 20px;

      padding: 20px;
      //padding: 10px 0;
      //width: 100vw;
      //max-width: 760px;
      height: 100vh;
      height: -webkit-fill-available;

      display: flex;
      flex-direction: column;
    }

    .app-item-specialty2 {
      position: absolute;
      left: 0;
      top: 0;
      //width: calc(100vw - 40px);
      width: 100vw;
      //width: calc(100vw - 40px);
      //width: 100vw;
      max-width: 760px;
      //margin: 0 20px;

      padding: 20px 0;
      //padding: 10px 0;
      //width: 100vw;
      //max-width: 760px;
      height: 100vh;
      height: -webkit-fill-available;

      display: flex;
      flex-direction: column;
    }

    .app-item-app-top {
      min-height: 55px;
      height: 55px;

      img {
        float: right;
        cursor: pointer;
      }
    }

    .app-item-app-top2 {
      min-height: 55px;
      height: 55px;
      //padding: 0 0 0 20px;
      padding: 0 20px;

      &.with-util {
        height: 96px;
        min-height: 96px;
        max-height: 96px;
      }
    }

    .search-utils {
      display: flex;

      .search-switch {
        margin: 10px 0;
        flex: 2;
        box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.25);
        display: flex;
        border-radius: 8px;

        .search-switch-item {
          user-select: none;
          cursor: pointer;
          transition: all .25s;
          flex: 1;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;

          &.selected {
            background: #1899F5;
            border-radius: 8px;
            color: white;
          }
        }
      }

      .search-button {
        user-select: none;
        cursor: pointer;

        &:active {
          //background: #f4f4f4;
          background: #5eabe3;
        }

        flex: 1;

        margin: 10px 0 10px 40px;

        text-align: center;
        padding: 10px 20px;
        font-size: 14px;
        vertical-align: middle;

        font-weight: bold;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
        width: 90px;

        background: #1899F5;
        color: #fff;

        &.disabled {
          opacity: .25;

          &:active {
            //background: #fff;
            background: #1899F5;
          }
        }
      }
    }

    .app-item-app-rest {
      flex: auto;
      //display: flex;
      overflow: auto;

      .no-consultants-found {
        padding: 20px;
      }

      .specialty-item {
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #f2f2f2;
        }

        margin-bottom: 12px;
        border: 1px solid #898A8D;
        border-radius: 30px;
        padding: 10px 20px;
        font-size: 14px;

        &.centered {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }

  .page-submit-button {
    user-select: none;
    cursor: pointer;

    margin: 10px 0;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background: #1899F5;
    border-radius: 8px;

    &.disabled {
      background: #eaeaea;
      cursor: default;
    }
  }

  .app-item-consultants {
    margin-top: 20px;
  }
}