.single-consultant-panel {
  background: #FFFFFF;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  min-height: 145px;

  position: relative;

  padding: 10px;
  margin: 10px 20px 20px;
  width: calc(100% - 60px);

  .flexi {
    display: flex;
    flex-direction: row;
  }

  &.no-padding {
    margin: 10px 0 20px;
    width: calc(100% - 20px);
  }

  &.placeholder {
    opacity: .75;
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    box-shadow: none;
  }

  &.single-consultant-unavailable {
    opacity: .5;
  }

  &.cancelable {
    padding-bottom: 40px !important;
  }
}

.consultant-panel-items {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  .consultant-panel-items-p1 {
    width: 120px;

    .consultant-image {
      display: inline-block;

      .consultant-image-img {
        width: 110px;
        height: 140px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .consultant-panel-items-p2 {
    flex: auto;

    .consultant-panel-full-name {
      color: #1899F5;
      font-size: 16px;
      font-weight: bold;
    }

    .with-spacing {
      margin-bottom: 10px;
    }

    .consultant-panel-info-line1 {
      font-size: 13px;
      font-weight: bold;
    }

    .consultant-panel-info-line2 {
      font-size: 13px;

      .select-button {
        float: right;
        //margin-right: 5px;
        //margin-bottom: 10px;
        margin-top: -10px;
        background: #1899F5;
        border-radius: 30px;
        padding: 5px 20px;

        color: #FFFFFF;
        font-size: 13px;
      }
    }
  }
}

.consultant-panel-item1 {
  width: 110px;
  max-width: 110px;
  min-width: 110px;

  .empty-picture {
    width: 110px;
    height: 120px;

    .empty-picture-img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #eaeaea;
    }
  }

  .consultant-image {
    display: inline-block;

    .consultant-image-img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 50%;
      //border: 2px solid rgba(255, 255, 255, .5);
      //box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
    }
  }
}

.consultant-buttons-parent {
  //position: relative;
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.consultant-buttons {
  //position: absolute;
  //position: fixed;
  //left: 0;
  //right: 0;
  //display: block;
  text-align: center;
  //margin-top: -35px;
  //z-index: 1;
  display: flex;
  width: 100%;
  //bottom: 10px;

  //position: relative;

  .consultant-profile-button {
    display: inline-block;
    font-size: 12px;
    //font-weight: bold;
    border: 1px solid #BBBBBB;
    color: #888;
    //border-radius: 30px;
    //text-align: center;
    padding: 9px 10px;
    //width: 90px;
    flex: 1;
    cursor: pointer;
    user-select: none;

    //background: #1899F5;
    //border: 1px solid #1899F5;
    border-radius: 8px;
    //color: #fff;
    text-align: center;
    font-weight: bold;

    &.disabled {
      opacity: .15;
      cursor: default;
    }
  }
  .consultant-buttons-separator {
    //flex: auto;
  }
  .consultant-select-button {
    display: inline-block;
    //background: #1899F5;
    //border-radius: 30px;
    padding: 9px 0;

    margin-left: 10px;

    cursor: pointer;

    //width: 90px;
    flex: 3;

    //color: #FFFFFF;
    font-size: 13px;

    margin-right: 20px;

    background: #1899F5;
    border: 1px solid #1899F5;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }


  &.cancelable {
    margin-top: 10px;
    .consultant-select-button {
      display: block;

      background: #D1EBFD;
      border-radius: 8px;
      //margin-right: 40px;
      width: 100%;
      color: #000;
      font-weight: bold;
      font-size: 14px;

      //margin: 5px 0;

      padding: 7px 0;
    }
  }

}

.consultant-panel-item2 {
  //width: 40px;
  flex: auto;

  .consultant-panel-date {
    //padding-bottom: 10px;
    //font-weight: bold;
  }
  .consultant-panel-hour {
    padding-bottom: 10px;
    font-weight: bold;
    //text-align: right;
    font-size: 18px;
  }

  .consultant-panel-full-name {
    color: #1899F5;
    font-size: 16px;
    font-weight: bold;
  }

  .consultant-panel-info-line1 {
    font-size: 13px;
    font-weight: bold;
  }

  .consultant-panel-info-line2 {
    font-size: 13px;

    .select-button {
      float: right;
      //margin-right: 5px;
      //margin-bottom: 10px;
      margin-top: -10px;
      background: #1899F5;
      border-radius: 30px;
      padding: 5px 20px;

      color: #FFFFFF;
      font-size: 13px;
    }
  }

  .with-spacing {
    margin-bottom: 10px;
  }
}

.profile-page {
  //padding: 20px 0;
  //padding: 10px 0;
  width: 100vw;
  max-width: 760px;
  height: 100vh;
  height: -webkit-fill-available;

  // FIXME: Daryn footer ...
  //padding-bottom: 80px;

  //display: flex;
  //flex-direction: column;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 20;

  //padding: 20px;
  background: #fff;
  overflow: auto;

  .profile-page-close-button {
    padding: 10px 20px 50px;
    font-weight: bold;
  }

  .profile-page-submit-button {
    margin: 10px 20px;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background: #1899F5;
    border-radius: 8px;
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;

  .profile-top-label {
    .close-button {
      float: right;
      //font-size: 22px;
      line-height: 24px;
      cursor: pointer;
    }

    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 15px 20px;
  }

  .profile-top {

  }

  .profile-page-selector {
    padding: 0 20px;

    .selector-item {
      font-size: 20px;
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 5px;
      cursor: pointer;

      &.selected {
        color: #1899F5;
      }
    }
  }

  .profile-page-text {
    padding: 5px 20px 20px;
    font-size: 14px;

    flex: auto;
    overflow: auto;
  }
}