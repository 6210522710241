@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

.phone-call {
  color: #000 !important;
  cursor: pointer;
  //font-weight: bold;
  text-decoration: underline;
}
.app-modal-message {
  position: absolute;
  z-index: 80;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  //background-color: rgba(255, 255, 255, .95);
  background-color: rgba(0, 0, 0, .55);

  flex-direction: column;

  .message-stretch {
    flex: auto;
  }

  .message {
    //background-color: #fff;
    //height: 200px;
    padding: 15px 0 10px;
    margin: 0 20px;

    background: #D1EBFD;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .message-header {
      font-size: 11px;
      font-weight: bold;
      margin: 0 20px;
      //text-align: center;
      //line-height: 48px;

      display: flex;

      .message-header-label {
        //display: inline-block;
        //flex: 1;
        border-radius: 4px;
        padding: 7px;
        user-select: none;
        cursor: default;
        &.error {
          background: #FF647C;
          color: #fff;
        }
        &.info {
          background: #1899F5;
          color: #fff;
        }
      }
      .message-header-close {
        user-select: none;
        cursor: pointer;
        flex: auto;
        text-align: right;
      }
    }

    .message-body {
      font-size: 14px;
      margin: 20px 20px 10px;
      line-height: 20px;
      //text-align: center;
    }

    .message-buttons {
      display: flex;
      .message-button {
        user-select: none;
        cursor: pointer;

        flex: auto;
        margin: 10px 20px;
        padding: 10px 20px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        background: #1899F5;
        border-radius: 8px;

        &.disabled {
          background: #eaeaea;
        }
        &.important {
          background: #FF647C;
        }
      }
    }
  }
}

.App {
  font-family: "Lato", sans-serif;
  background-color: #fff;
  overflow: hidden;

  margin: 0 auto;
  max-width: 760px;

  .app-header {
    //padding: 50px;
    //height: calc(100vh - 50px - 100px);
    //height: calc(100vh - 50px - 100px);
    overflow: hidden;
  }

  .side-menu-back {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    .side-menu-body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20vw;
      min-width: 250px;

      border-radius: 15px 0 0 15px;

      background-color: #fff;

      .side-menu-close {
        padding: 20px;
        img {
          width: 16px;
          opacity: .5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }

      .side-menu-options {
        padding: 0 20px;
        margin-top: 40px;

        .navigationLink {
          margin-bottom: 20px;
          &.l-home {
            a {
              color: #57b6f4;
            }
          }

          .nav-link-img {
            display: inline-block;
          }
          .nav-link-lbl {
            display: inline-block;
            vertical-align: top;
            text-decoration: none;
            color: #666;
            line-height: 24px;
          }

          a {
            text-decoration: none;
          }

          svg {
            padding-right: 20px;

            &.l-home {
              stroke: #57b6f4;
            }
            &.l-appointments {
              stroke: #2C3D4A;
            }
            stroke: #2C3D4A;
            //stroke: red;
          }
        }
      }
    }
  }

  .loading-page {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    background: rgba(255, 255, 255, .75);

    .loading-info {
      width: 84px;
      height: 64px;
      position: absolute;
      margin-left: calc(50% - 100px);
      margin-top: calc(50vh - 100px);
      opacity: .75;
    }
    .loading-text {
      text-align: center;
    }
  }

  .app-full {
    position: absolute;
    width: 100vw;
    max-width: 760px;
    height: 100vh;
    height: -webkit-fill-available;

    display: flex;
    flex-direction: column;

    .app-top {
      height: 110px;
      padding: 10px 20px;

      &.list-only {
        height: auto;
        padding-bottom: 0;
      }

      //margin-bottom: 10px;
      &.app-scroll-enabled {
        //box-shadow: 0px 5px 29px -10px rgba(66, 68, 90, 0.3);
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      }

      .app-top-text {
        font-size: 20px;
        padding: 22px 0;
        font-weight: bold;
        text-align: center;
        .go-back-stage {
          cursor: pointer;
          float: left;
          color: #CACACA;
        }

        .app-top-go-back {
          margin-left: 10px;
          float: left;
          line-height: 36px;
          cursor: pointer;
        }
        .app-top-close {
          margin-right: 10px;
          float: right;
          line-height: 36px;
          cursor: pointer;
        }
      }

      .new-app-stepper {
        width: 100%;
        height: 30px;
        //padding: 0 10px;
        position: relative;
        .line-full {
          height: 3px;
          background-color: #CACACA;
          position: absolute;
          z-index: 1;
          margin-top: 12px;
          left: 10px;
          right: 10px;
        }
        .line-progress {
          height: 3px;
          background-color: #1899F5;
          position: absolute;
          z-index: 2;
          margin-top: 12px;
          left: 10px;
          //right: 10px;
          &.progress-q0 {
            //right: calc(((100% - 46px) * 3) / 4 + 26px);
          }
          &.progress-q1 {
            right: calc(((100% - 46px) * 3) / 4 + 26px);
          }
          &.progress-q2 {
            right: calc(((100% - 46px) * 2) / 4 + 26px);
          }
          &.progress-q3 {
            right: calc(((100% - 46px) * 1) / 4 + 26px);
          }
          &.progress-q4 {
            right: 10px;
          }
        }

        .single-step {
          z-index: 3;

          font-size: 12px;

          display: inline-block;
          //width: 20%;
          //position: relative;
          //margin-right: -13px;
          position: absolute;

          .check-me {
            margin-top: 2px;
          }
          &.number {
            width: 26px;
            height: 26px;
            line-height: 26px;
            font-weight: bold;
            border-radius: 50%;
            text-align: center;

            background: #fff;
            border: 1px solid #CACACA;
            color: #CACACA;
            &.selected {
              background: #1899F5;
              color: #fff;
              border: none;
            }
            &.done {
              background: #1899F5;
              color: #fff;
              border: none;
            }
          }

          &.number-1 {
            left: calc(10px);
          }
          &.number-2 {
            left: calc(((100% - 46px) * 1) / 4);
          }
          &.number-3 {
            left: calc(((100% - 46px) * 2) / 4);
          }
          &.number-4 {
            left: calc(((100% - 46px) * 3) / 4);
          }
          &.number-5 {
            right: 10px;
          }

          //text-align: center;
          //.number {
          //  display: inline-block;
          //  position: absolute;
          //  //margin-left: -13px;
          //  //top: 10px;
          //}
        }
      }

      .logic-background {
        position: fixed;
        z-index: 1;
        width: 100vw;
        height: 110px;
        //padding: 10px 20px;
        padding: 5px 0;
        left: 0;
        top: 0;
        background: #fff;
      }

      .menu-logo {
        position: relative;
        z-index: 2;
        display: inline-block;
        //width: calc(100% - 28px);
        width: 100%;
        text-align: center;

        .logo-img {
          width: 56px;
        }

        .burger {
          //position: fixed;
          //top: 10px;
          //right: 10px;
          line-height: 60px;
          cursor: pointer;
          float: right;
        }
      }

      &.list-only {
        .burger {
          line-height: 34px;
        }
      }

      .menu-icon {
        display: inline-block;
        //float: right;
        text-align: right;

        height: 43px;
        line-height: 43px;
        vertical-align: top;

        svg {
          vertical-align: middle;
        }
      }
    }

    .app-main-page {
      //height: calc(100vh - 70);
      padding: 0 20px;
      flex: auto;
      overflow: auto;
    }

    .mobile-version {
      @media (min-width: 760px) {
        display: none;
      }
    }
    .desktop-version {
      @media (max-width: 760px) {
        display: none;
      }
    }

    .app-footer {
      width: 100%;

      .footer-copy {
        padding: 0 30px;
        font-size: 12px;
      }
      .row {
        display: flex;
        .col-lg-2 {
          flex: 1;
          //width: 16.66666667%;
        }
        .col-lg-6 {
          flex: 2;
          //width: 50%;
        }
        .col-lg-4 {
          flex: 2;
          //width: 50%;
          text-align: right;
        }
        .hyperlink-text {
          color: rgb(33, 37, 41);
          text-decoration: none;
        }
      }
      &.bar {
        height: 70px;
        background-color: #fff;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 -5px 22px 9px #cfcfcf69;
        font-size: 25px;
        font-weight: 700;
      }
      .navbar {
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        a {
          padding: 0 20px;
          line-height: 70px;
          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .fill-all-test {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 10px;
    right: 10px;
    background-color: red;
  }

  .a-header {
    height: 48px;
    background-color: pink;
  }

  .a-body {
    background-color: white;
    height: calc(100vh - 48px - 48px);
    //min-height: -webkit-fill-available;
    overflow: auto;
  }

  .a-footer {
    height: 48px;
    background-color: blue;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  //.app-nav-footer {
  //  position: fixed;
  //  //bottom: 0;
  //  //top: 0;
  //  //bottom: "100%";
  //  //position: absolute;
  //  bottom: 0;
  //  height: 50px;
  //  width: calc(100vw - 100px);
  //  padding: 0 50px;
  //}

  .back-button {
    width: 100%;
  }
}

.consultant-panel-location {
  font-weight: bold;
  //font-variant: small-caps;
  //text-transform: uppercase;
  //font-size: 90%;
}

.app-page-main {
  //max-width: 760px;
  //max-width: 300px;
  //&.slot-margin {
  //  margin: 10px 0;
  //}
}
