.confirm {
  .confirm-summary {
    margin-top: 20px;
  }
  .confirm-select {
    margin-top: 20px;
  }

  .para {
    font-size: 12px;
    font-weight: bold;
    &.ma {
      margin-bottom: 10px;
    }
  }

  .ma2 {
    margin-bottom: 20px;
  }

  .page-body {
    margin-top: 20px;
  }

  .authorization {
    border: 1px solid #1899F5;
    border-radius: 76px;
    padding: 10px 20px;
    margin: 20px 0px;
    color: #1899F5;
    .authorization-label {
      display: inline-block;
      width: 50%;
    }
    .authorization-code {
      display: inline-block;
      font-weight: bold;
      text-align: right;
      width: 50%;
    }
  }

  .error-page {
    background-color: #D1EBFD;
    border-radius: 12px;
    padding: 20px;
    &.oneLine {
      display: flex;
    }
  }

  .error-action {
    width: fit-content;
    border-radius: 4px;
    font-size: 11px;
    color: #FFFFFF;
    text-align: center;
    padding: 5px 15px;
    &.error {
      background-color: #FF647C;
    }
    &.info {
      background-color: #1899F5;
    }
  }

  .error-body {
    padding-top: 10px;
    font-size: 14px;
    text-align: left;
    color: #3D4B5A;
    line-height: 23px;
    font-weight: normal;
    width: fit-content;
    &.oneLine {
      padding-top: 0;
      margin-left: 10px;
    }
    a {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}